import { NavLink } from 'react-router-dom';
// Icons
import { Arrow } from './Icons';
// ----------------------------------------------------------------------


export default function NavItem({ dashboard, handleClickNavItem, routeHasSubNav }) {

  // ---------- JSX Code ---------
  return (
    <NavLink
      to={dashboard.url}
      className={`sidenav_link ${dashboard.show ? "active" : ""
        }`}
      onClick={(e) => handleClickNavItem(e, dashboard, routeHasSubNav)}
    >
      <span className="sidenav_icon">{dashboard.icon}</span>
      <span className="dash-text"> {dashboard.text}</span>
      {routeHasSubNav &&
        <span
          className={`sidenav_arrow ${dashboard.show && routeHasSubNav ? "toggled" : ""
            }`}
        >
          <Arrow />
        </span>
      }
    </NavLink>
  )
}
