import resetIcon from "assets/images/wallet/resetIcon.png";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import Locale from "translations";

export default function OtaFilter({ filter, setFilter }) {
	const { backOffice } = Locale;

	const statusOptions = [
		{ value: "builder_request_package", label: "Package" },
		{ value: "builder_request_flight", label: "Flight" },
		{ value: "builder_request_hotel", label: "Hotel" },
		{ value: "builder_request_transfer", label: "transfer" },
		{ value: "builder_request_tour", label: "Tour" },
		{ value: "builder_request_other", label: "Other" },
	];

	return (
		<>
			<div className="border-top py-2 mt-2 w-100">
				<div className="row align-items-center w-100 no-gutter m-0">
					<div className="col-3">
						<TextField
							type="text"
							placeholder={backOffice.name}
							label={backOffice.name}
							value={filter.client_name}
							onChange={(e) => {
								setFilter({
									...filter,
									client_name: e.target.value,
								});
							}}
						/>
					</div>
					<div className="col-3">
						<TextField
							type="text"
							placeholder={"company Name"}
							label={"company Name"}
							value={filter.company_name}
							onChange={(e) => {
								setFilter({
									...filter,
									company_name: e.target.value,
								});
							}}
						/>
					</div>

					<div className="col-3">
						<SelectField
							label={backOffice.type}
							placeholder={backOffice.type}
							name="status"
							options={statusOptions}
							value={filter?.type}
							onChange={(e) => {
								debugger;
								setFilter({
									...filter,
									type: e,
								});
							}}
						/>
					</div>

					<div
						className="col-3"
						onClick={() =>
							setFilter({
								name: "",
								companyName: "",
								type: null,
							})
						}
					>
						<div className="d-flex align-items-center text-secondary pointer mt-4 reset-btn">
							<img src={resetIcon} alt="" srcset="" />

							<p className="text-caption">{backOffice.resetFilter}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
