import { useState } from 'react';
// React-Router-Dom
import { NavLink } from 'react-router-dom';
// ReactStarp
import { Collapse } from "reactstrap";
// Helper
import ShowForPermission from 'helpers/showForPermission';
import { Arrow } from './Icons';
// ---------------------------------------------------------------------


export default function SubNavItem({ subNav, activeId, setActiveTab, isOpen }) {

  const subNavHasChildren = subNav?.children && subNav?.children?.length > 0

  const [activeSubNav, setActiveSubNav] = useState()

  const handleClickSubNavItem = (e, selectedSubNav) => {

    if (!subNavHasChildren) {
      setActiveTab(null);
    }

    subNavHasChildren && e.preventDefault();

    if (isOpen) {
      setActiveSubNav((prevState) => prevState === null ? selectedSubNav : null)
    }

  }


  // ------- JSX Code -------
  return (
    <ShowForPermission
      permission={subNav?.permission}
    >
      <li
        className="dropdown_item"
        onClick={() => setActiveTab(subNav.id)}
      >

        <NavLink
          to={subNav.url || ''}
          value={subNav?.id ?? ""}
          className={`dropdown_link pointer ${activeId == subNav.id ? "active" : ""
            }  `}
          onClick={(e) => handleClickSubNavItem(e, subNav)}
        >
          <span className="sidenav_icon">
            {subNav?.icon}
          </span>
          <span className="dash-text">
            {subNav?.text}
          </span>
          {
            subNavHasChildren &&
            <span className={`sidenav_arrow ${activeSubNav?.id && subNavHasChildren ? "toggled" : ""}`}>
              <Arrow />
            </span>
          }
        </NavLink>

        {subNav?.children && (
          <ul className="sidenav_dropdown">
            {isOpen ? (
              <Collapse isOpen={subNav?.id === activeSubNav?.id}>
                {subNav?.children?.map((child) =>
                  <SubNavItem
                    dashboard={subNav}
                    subNav={child}
                    activeId={activeId}
                    setActiveTab={setActiveTab}
                  />
                )}
              </Collapse>
            ) : (
              subNav?.children?.map((child) => {
                return (
                  <ShowForPermission
                    permission={child?.permission}
                  >
                    <li className="dropdown_item">
                      <NavLink
                        exact
                        to={child?.url ?? ""}
                        value={child?.id ?? ""}
                        className="dropdown_link"
                        activeClassName="active"
                      >
                        {child?.text}
                      </NavLink>
                    </li>
                  </ShowForPermission>
                );
              })
            )}
          </ul>
        )}

      </li>


    </ShowForPermission>
  )

}