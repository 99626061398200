export default function RequestFeild({
	labelValue,
	inputValue,
	width = "100%",
}) {
	return (
		<div style={{ width: width }} className="request-feild rounded p-2">
			<p className="request-label">{labelValue}</p>
			<p className="request-input">{inputValue ? inputValue : "--"} </p>
		</div>
	);
}
