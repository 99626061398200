import { useEffect } from "react";
// React-Router-Dom
import { NavLink, useRouteMatch } from "react-router-dom";
// ReactStarp
import { Collapse } from "reactstrap";
// Context
import { useGlobalDispatch } from "context/global";
// Custom Hook
import useSideBarRoutes from './useSideBarRoutes';
// Services
import { getPermissions } from "services/auth";
// Helper
import ShowForPermission from "helpers/showForPermission";
// Icons
// import { Arrow } from './Icons';
import NavItem from './NavItem';
import SubNavItem from './SubNavItem';
// ---------------------------------------------------------------------

const SideBar = ({ isOpen, isMobile }) => {

	// Hooks
	let { url } = useRouteMatch();

	const { routes, setRoutes } = useSideBarRoutes()

	// Context
	const dispatch = useGlobalDispatch();

	//*** Function
	const setActiveTab = (value) => {
		localStorage.setItem("activetab", value);
	};

	const handleClickNavItem = (e, dashboardItem, routeHasSubNav) => {
		let active = url.includes(dashboardItem?.url);

		if (!routeHasSubNav) {
			setActiveTab(null);
		}

		routeHasSubNav && e.preventDefault();
		if (isOpen) {
			setRoutes(
				routes.map((route) => {
					return {
						...route,
						show:
							route.id === dashboardItem.id
								? !dashboardItem.show
								: false,
						active:
							route.id === dashboardItem.id ? !active : false,
					};
				})
			);
		}

	}

	const activeId = localStorage.getItem("activetab");

	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();
			dispatch({ type: "getPermissions", payload: res?.data });
		}
		fetchPermissions();
	}, []);


	// ----------- JSX Code ------
	return (
		<nav
			id="sideNav"
			className={`${isOpen && !isMobile ? "opend" : "closed"}`}
		>
			<div style={{ position: "absolute", left: "0", width: "235px" }}>
				<ul className="sidenav_list  scrollable-div">
					<li className="menu-title">Menu</li>
					{/* <li className="sidenav_item">
							<Link
								exact
								to="/"
								className={`sidenav_link ${url == "/" ? "active" : ""}`}
							>
								<span className="sidenav_icon">
									<MainMenuIcon />
								</span>
								<span className="dash-text"> Home</span>
							</Link>
						</li> */}
					{routes.map((dashboard) => {
						const routeHasSubNav =
							dashboard?.subNavs && dashboard?.subNavs.length > 0
								? true
								: false;

						return (
							<ShowForPermission permission={dashboard.permission}>
								<li className="sidenav_item">

									<NavItem
										dashboard={dashboard}
										handleClickNavItem={handleClickNavItem}
										routeHasSubNav={routeHasSubNav}
									/>

									{/* dropdown */}
									{routeHasSubNav ? (
										<ul className="sidenav_dropdown">
											{isOpen ? (
												<Collapse isOpen={dashboard.show}>
													{dashboard?.subNavs?.map((subNav, index) =>
														<SubNavItem
															dashboard={dashboard}
															subNav={subNav}
															activeId={activeId}
															setActiveTab={setActiveTab}
															isOpen={isOpen}
														/>
													)}
												</Collapse>
											) : (
												dashboard?.subNavs?.map((subNav) => {
													return (
														<ShowForPermission
															permission={subNav?.permission}
														>
															<li className="dropdown_item">
																<NavLink
																	exact
																	to={subNav?.url ?? ""}
																	value={dashboard?.id ?? ""}
																	className="dropdown_link"
																	activeClassName="active"
																>
																	{subNav?.text}
																</NavLink>
															</li>
														</ShowForPermission>
													);
												})
											)}
										</ul>
									) : null}
								</li>
							</ShowForPermission>
						);
					})}
				</ul>
			</div>
		</nav>
	);
};

export default SideBar;
