import Pagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { getOtaRequestsList } from "services/b2c";
import OtaFilter from "./OtaFilter";
import OtaTable from "./OtaTable";

export default function OtaRequestsList() {
	const [Requests, setRequests] = useState([]);
	const [filter, setfilter] = useState({
		client_name: "",
		company_name: "",
		type: null,
	});
	const [page, setpage] = useState(null);
	const [meta, setmeta] = useState([]);

	function goto(page) {
		setpage(page);
	}
	async function fetchOtaRequests() {
		const { client_name, company_name, type } = filter;
		const res = await getOtaRequestsList({
			client_name,
			company_name,
			type: type?.value,
			page,
		});
		if (res?.status === 200) {
			setRequests(res?.data?.data);
			setmeta(res?.data?.meta);
		}
	}
	useEffect(() => {
		fetchOtaRequests();
	}, [filter, page]);

	return (
		<div>
			<h6>OTA Requests List</h6>
			<OtaFilter filter={filter} setFilter={setfilter} />
			<OtaTable requests={Requests} />
			<Pagination info={meta} goTo={goto} />
		</div>
	);
}
