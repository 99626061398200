import { EyeIcon } from "assets/images/icons/OutlineIcons";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function OtaTable({ requests }) {
	return (
		<div className="table-container">
			<table className="g-table">
				<thead>
					<tr>
						<th>ID</th>
						<th>Company</th>
						<th>Name</th>
						<th>Req.Date</th>
						<th>Service Name</th>
						<th>ACTIONS</th>
					</tr>
				</thead>
				<tbody>
					{" "}
					{requests?.map((request) => (
						<tr key={request?.id}>
							<td className="gray-text">#{request?.id}</td>
							<td>{request?.company_name}</td>
							<td>{request?.client_name}</td>
							<td className="gray-text">
								{moment(request?.created_at).format("DD/MM/YYYY")}
							</td>
							<td>
								<p>{request?.name}</p>
								<span className="gray-text">{request?.type}</span>
							</td>

							<td>
								<Link to={`/b2c-ota-requests/view/${request?.id}`}>
									<EyeIcon />
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
